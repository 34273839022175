/* ChatBox.css */
.chat-box-container {
    width: 300px;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 20px auto;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  .message-container {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .message {
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
  }
  
  .sent {
    background-color: #007bff;
    color: white;
    text-align: right;
  }
  
  .received {
    background-color: #f1f0f0;
    text-align: left;
  }
  
  .input-container {
    display: flex;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    outline: none;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  