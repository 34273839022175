
.tick-img-pricing {
    height: 20px;
    width: 20px;
}

.pricing-para{
    color: #A3A6AD;
    font-size: 14px;
}

.heading-pricing{
color:white;
margin-top: 100px;
text-align: center;
}

.heading-pricing h1{
    font-size: 25px;
    margin-bottom: 10px;
    }
.heading-pricing a{
    border-top: 7px solid var(--primary-color);
    color:black;
    text-align: center;
    margin-top: 10px;
        }

.price-card{
            border-radius: 25px;
            color:white;
            height: 300px;
            width: 60%;
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            padding:20px;
            background-color: rgb(50, 49, 49);
            justify-content: center;
            display:flex; 
            align-items: center;
            
            
        }
.price{
    display: block;
}
.pricing-whole{
    display: flex;
}
.price-card-left {
    border-right: 1px white solid;
    height: 100%;
    /* flex-grow: 1;
    flex-basis: 0; */
   
    
    
}

.price-card-right {
    height: 100%;
    /* flex-grow: 1.5;
    flex-basis: 0; */
    flex-direction: column;
   
}

.words{
    width: 235px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.money{
    display: flex;
}

.price{
    display:block;
}
.points-col {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    padding-left: 35px;
}


.points-row {
    display: flex;
    padding:15px 20px;
}

.points-para {
    width: max-content;
    margin-left: 10px;
}

@media (max-width: 800px) {

    .price-card {
        flex-direction: column;
        height: auto;
        width: 90%;
       
    }

    .price-card-left {
        border-bottom: 1px white solid;
        border-right: 0px;
    }

    .words {
        height: 300px;
    }

    .points-col {
        padding-top: 35px;
        height: 400px;
    }

    .points-row {
        display:flex;
        
    }

    .points-para {
        width: auto;
    }

}


@media (max-width: 1000px) {

    .price-card {
        flex-direction: column;
        height: auto;
        width: 90%;
        
    }

    .price-card-left {
        margin-top: 10px;
        border-bottom: 1px white solid;
        border-right: 0px;
    }
}
@media (max-width: 1150px) {
    .price-card-left {
        margin-top: 10px;
        height: 100%;
        width:50%
    }
    
    .price-card-right {
        margin-bottom: 20px;
        height: max-content;
       
        flex-direction: column;
        width:50%
    }
}

@media (max-width: 1000px){
    .price-card-left {
       
        height: 100%;
    }
    
    .price-card-right {
        height: 100%;
       
        flex-direction: column;
       
    }
}