.card-contents {
  width: 70%;
  margin: auto;
 
  
}

.center{
  display: flex;
  flex-direction: column;
}

.what{
    color:white;
    margin-top: 100px;
    text-align: center;
   
}

.what h1{
    font-size:40px;
}

.what a{
    border-top: 7px solid var(--primary-color);
    color:black;
    text-align: center;
    margin-top: 20px;
    
}


.image{
  size:10px;
}

.row {
    display: flex;
    justify-content:space-around;
    margin-left: 20px;
    margin-right:30px;
    margin-top:35px;    
    
  }
  
  .cardd {
   
    padding: 20px 20px;
    margin: 10px;
    border-radius: 30px;
    background-color: rgb(50, 49, 49);
    width: 400px;
    min-height: 200px;
  }
  
  .card-icon {
    display: flex;
    padding:10px;
  }
  
  .card-heading {
   color:white;
   margin-left:20px;
   font-size: 20px;
  }
  
  .card-para {
   color: white;
   font-size: 14px;
  }
  
  @media (max-width:800px )  {

   .row{
    flex-direction: column;
    margin-top: 0px;
   }
   .cardd{
    margin: 10px auto;
    width: 230px;
    min-height: 200px;
   }
   .card-icon {
    margin: 20px 0px;
    padding: 0px;
  }

  .card-heading {
    
    font-size: 15px;
   }

   .card-para{
    text-align: justify;
    word-spacing: -2px
   }
   
}