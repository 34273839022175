.footer-card {


  height: 250px;
  background-color: white;
  width: 100%;

  margin-top: 100px;
  padding-top: 35px;
  padding-right: 30px;
  padding-left: 30px;
  justify-content: space-around;
  display: flex;

}

.footer-logo {
  margin-top: 40px;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-evenly;

}

.footer-row {
  display: flex;

}


.footer-para {
  width: max-content;
  margin-left: 20px;
}

.tick-img {
  height: 20px;
  margin-right: 10px;
}

.footer-line a {
  font-weight: 600;
  color: black;
  text-decoration: none;
  font-size: 14px;

}

.footer-line1 {
  font-weight: 600;
  color: black;

  font-size: 14px;

}

@media (max-width: 800px) {

  .footer-card {
    flex-direction: column;
    height: auto;
    margin-top: 50px;
    align-items: center;
  }

  .footer-col {
    flex-direction: column;
    /* margin-left: 350px; */
    margin-bottom: 60px;
  }

  .footer-logo {
    /* margin-left: 80px; */
    margin-bottom: 30px;
  }

  .footer-row {
    margin-top: 20px;
  }

}