
.home{
    background-color: black;
    text-align: center;
    margin-top: 150px;
}

.h1{
    color: white;
    margin: 30px;
    font-size: 30px;
    font-weight: 700;
}
.p{
    margin: 30px ;
    color: #A3A6AD;
    font-size: 16px;
    font-weight: 500;
}
.home-btn {
    border: none;
    color: rgb(255, 255, 255);
    background-color:#c73274;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    width: 140px;
    border-radius: 8px;
    margin: 0 10px ;
    cursor: pointer;
    transition: .4s;
    
}
.talktous{
    text-decoration: none;
}

.home-btn:hover{
    background-color: white; 
    color: black;
}

.card{
    width:70%;
    border-radius: 25px;
    border:1px solid #A3A6AD;
   
    padding: 30px 40px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    justify-content: space-between;
    display: flex; 
    
    
}

.inner-card{
   display: flex; 
   align-items: center; 
   padding: 0px 20px;
}

.icon{
    color:white;
    margin-right: 10px;
}

.heading{
    color:white;
    
}

@media (max-width:800px )  {
    
    .card{
        
       display: flex;
       flex-direction: column;
    
    }

    .inner-card {
        margin: 20px 0px;
    }

    .icon {
        margin-right: 20px;
    }
    
}