.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 50px;
    background-color: #ffffff;
    border-bottom: 2px solid #818181;
    position: fixed;
    width: 100%;
    top:0;
    
}

.nav-btn {
    display: none;
    border: none;
    color: rgb(255, 255, 255);
    background-color:#c73274;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    width: 50px;
    border-radius: 8px;
    margin: 0 10px ;
    cursor: pointer;
    transition: .4s;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.navbar li {
    list-style: none;
    padding: 0px 20px;
}

.navbar li a {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.navbar li :hover {
    border-bottom: 3px solid var(--primary-color);
}

.logo1 {
    width: 120px;
}

@media (max-width:800px) {

    .nav-btn {
        display: block;
    }

    .navbar {
        display: none;
        flex-direction: column;
        position: absolute;
        background-color: white;
        height: 300px;
        width: 40%;
        right: 60px;
        border-radius: 30px;
    }

    .navbar li {
        padding: 20px;
    }

    .navbar.show {
        display: flex;
    }

}