.heading-process {
    color: white;
    margin-top: 100px;
    text-align: center;

}

.heading-process h1 {
    font-size: 40px;
}

.heading-process a {
    border-top: 7px solid var(--primary-color);
    color: black;
    text-align: center;
    margin-top: 10px;

}

.heading-mobile-app {
    font-size: 18px;
    color: white;
    margin-left: 5%;
}

.title {
    margin: 20px;
    font-size: 25px;
}

.center-content {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.mob-card {
    border-radius: 25px;
    border: 1px solid #A3A6AD;
    height: 300px;

    padding-left: 5%;
    margin-top: 10px;

    justify-content: space-between;
    display: flex;

}

.mob-img-1-div {
    display: flex;
    align-items: center;
}

.mob-img-1 {
    width: 100%;
    height: 50%;
}

.mob-img-2 {
    width: 100%;
    height: 100%;
}

.heading-des-app {
    font-size: 18px;
    color: white;
    margin-left: 10%;
    margin-top: 40px;

}

.des-card {
    border-radius: 15px;
    border: 1px solid #A3A6AD;
    padding: 20px 20px;
    background-color: rgb(50, 49, 49);




}

.des-inside-card {
    /* flex-wrap: wrap; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.des-image {
    /* padding: 10px 20px; */
    width: 200px
}

.design-card {
    border-radius: 25px;
    height: 300px;
    background-color: rgb(50, 49, 49);
    width: 70%;
    margin-left: 185px;
    margin-top: 40px;
    padding-top: 35px;
    padding-right: 30px;
    padding-left: 30px;
    justify-content: space-around;
    display: flex;

}

.work-card {
    border-radius: 25px;

    height: 390px;
    background-color: rgb(50, 49, 49);
    width: 70%;
    margin-left: 185px;
    margin-top: 40px;
    padding-top: 35px;
    padding-right: 10px;
    padding-left: 30px;
    padding-bottom: 20px;
    justify-content: space-around;


}

.work-card-first-row {
    display: flex;

}

.work-card-second-row {
    display: flex;
    margin-top: 20px;
}

.first-row {
    margin-right: 20px;
}

.second-row {
    margin-right: 30px;
}




.frame-card {
    border-radius: 25px;
    background-color: rgb(50, 49, 49);
    width: 70%;
    margin-left: 185px;
    margin-top: 40px;
    padding-top: 35px;
    padding-right: 30px;
    padding-left: 30px;
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
}

.frame-inside-card{
    padding:20px 80px;
    width:auto;
    align-items: center;
    /* justify-content: space-between; */
    

}

.frame-logo {
    margin-left: 550px;
}

.frame1 {

    margin-left: 10px;
}

.frame2 {
    margin-top: 20px;
    margin-left: 130px;
}

.frame3 {
    margin-top: 20px;
    margin-left: 230px;
}


@media (max-width: 800px) {

    .des-inside-card {
        flex-direction: column;
        
    }

    .mob-card {
        flex-direction: column;
        padding-left: 0px;
        height: auto;
    }

    .center-content {
        width: 80%;
    }

    .mob-img-1 {
        height: 100%;

    }

    .mob-img-1-div {
        padding: 20px;
        padding-bottom: 0px;
    }

    .des-card {
        flex-direction: column;
        padding-left: 0px;
        height: auto;

    }

    .des-image {
        /* padding: 15px 20px; */
        width: 150px;
    }

    .frame-card {
     flex-direction: column;
        height: auto;
        padding-top: 5px;
        padding-right: 2px;
        padding-left: 2px;
    }

    .frame-logo {
        margin-left: 18px;
        width:30%;
        margin-bottom: 20px;
    }

    .frame1 {
        width: 100%;
    }

    .frame2 {
        width: 100%;
        margin-left: 10px;
    }

    .frame3 {
        width: 100%;
        margin-left: 13px;
    }

    .design-card {
        flex-direction: column;
        margin-left: 70px;
        height:auto;


    }

    .design-card1 {
        height: 50%;
        width: 100%
    }

    .design-card2 {
        height: 50%;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .work-card {
        flex-direction: column;
        margin-left: 70px;
        height: auto;

    }

    .work-card-first-row {
        margin-right: 0px;

        width: 100%
    }

    .work-card-second-row {
        margin-right: 10px;

        width: 100%;
    }

    .first-row {
        margin-right: 10px;
    }

    .second-row {
        margin-right: 10px;
    }

    .design-down-para {
        text-align: justify;
        margin: 0px 20px;
        word-spacing: -2px
    }

    .design-down-para br {
        display: none;
    }

    .communication-para {
        text-align: justify;
        margin: 0px 20px;
        word-spacing: -2px
    }

    .communication-para br {
        display: none
    }

    .para-project {
        text-align: justify;
        margin: 0px 20px;

    }

    .para-project br {
        display: none;

    }


}

@media (max-width: 900px) {

    .des-inside-card {
        flex-direction: column;
        
    }

}

@media (max-width:1100px){
    .frame-card {
        flex-direction: column;
           height: auto;
           padding-top: 5px;
           padding-right: 2px;
           padding-left: 2px;
           margin-left: 50px;
       }
   
       .frame-logo {
           margin-left: 18px;
           width:50%;
           margin-bottom: 20px;
       }
   
       .frame1 {
           width: 100%;
       }
   
       .frame2 {
           width: 100%;
           margin-left: 10px;
       }
   
       .frame3 {
           width: 100%;
           margin-left: 13px;
       }
}