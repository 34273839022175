.row-subscription {
    display: flex;
    margin-right:160px;
    margin-top:20px;  
    align-items: center;  
    margin-left: 170px;

  }
  
  .cardd-subscription {
    
    padding: 10px 20px;
    margin: 10px 10px;
    border-radius: 30px;
    color: white;
    min-height: 200px;
    text-align: center;
    flex-direction: row;
  }

  .para{
    font-size:11px;
    margin-top: 10px;
    color: #A3A6AD;
  }

  .heading-sub-ben{
    color:white;
    margin-top:60px;
    text-align: center;
  }
  
  .heading-sub-ben a{
    border-top: 7px solid var(--primary-color);
    color:black;
    text-align: center;
    margin-top: 10px;
  }
  .heading-sub{
    color:white;
    margin-top: 10px;
    text-align: center;
   
}

.heading-sub a{
    border-top: 7px solid var(--primary-color);
    color:black;
    text-align: center;
    margin-top: 10px;
}
  .great{
    margin-bottom: 15px;
  }

  .great-fit-card{
    border-radius: 25px;
    border:1px solid #A3A6AD;
    height: 250px;
    width: 60%;
    margin-left: 250px;
    margin-top: 30px;
    align-items: center;  
    padding: 5px 10px;

}

.great-fit-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  
}

.great-fit-row {
  display: flex;
  padding: 10px 8px;
  margin-top: 10px;
}

.great-fit-para {
  width:auto;
}

.tick-img {
  height: 20px;
  margin-right: 10px;
}

.para-line{
  color:white;
  
  font-size: 14px;

}

  @media (max-width:800px )  {
     
    .row-subscription{
        flex-direction: column;
        align-items: center;
        margin: 0px 60px;
    }

    .great-fit-card{
      flex-direction: column ;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      width: 80%;     
  } 
  .great-fit-row{
   
    margin: 20px 60px;
  }
  .tick-img{
    width: 20px;
    margin-bottom: 10px;
  }
  .great-fit-para {
    width: 200px;
  }
  .para-line{
    flex-direction: column;
  }

  .para br{
    display: none;
  }
    
   }
    
 